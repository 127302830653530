export default [{
	"paese": "Per\u00f9",
	"latitudine": "-12.057980",
	"longitudine": "-77.037132",
	"titolo": "ACOPAGRO",
	"impegno": "Tutela dell'ambiente, Lotta alla criminalit\u00e0",
	"prodotto": "cacao",
	"regione": "",
	"URL": "",
	"codice": ""
}, {
	"paese": "Nepal",
	"latitudine": "28.271671047023244",
	"longitudine": "83.78957358704992",
	"titolo": "ACP",
	"impegno": "Empowerment femminile, Protezione delle culture indigene",
	"prodotto": "prodotti artigianali",
	"regione": "Asia",
	"URL": "https://www.altromercato.it/acp/",
	"codice": "9810802"
}, {
	"paese": "Uganda",
	"latitudine": "1.5274059490924148",
	"longitudine": "32.48451535931211",
	"titolo": "ACPCU",
	"impegno": "Empowerment femminile, Sviluppo delle comunit\u00e0, Salvaguardia della biodiversit\u00e0",
	"prodotto": "caff\u00e8 ",
	"regione": "Africa",
	"URL": "https://www.altromercato.it/acpcu/",
	"codice": ""
}, {
	"paese": "Italia",
	"latitudine": "38.08199084203657",
	"longitudine": "15.647174721291973",
	"titolo": "AGRINOVA",
	"impegno": "Lotta al caporalato e/o alla criminalit\u00e0 mafiosa, Progetti per i giovani",
	"prodotto": "olio d'oliva",
	"regione": "Calabria",
	"URL": "https://www.altromercato.it/agrinova/",
	"codice": "9816231"
}, {
	"paese": "Filippine",
	"latitudine": "12.879721",
	"longitudine": "121.774017",
	"titolo": "ALTER TRADE",
	"impegno": "Sviluppo delle comunit\u00e0, Tutela dell'ambiente",
	"prodotto": "zucchero",
	"regione": "Asia",
	"URL": "https://www.altromercato.it/alter-trade/",
	"codice": "1111114"
}, {
	"paese": "Italia",
	"latitudine": "45.610477840771345",
	"longitudine": "12.351511481234013",
	"titolo": "ALTERNATIVA AMBIENTE",
	"impegno": "Inclusione sociale, Inserimento lavorativo di persone svantaggiate",
	"prodotto": "ceckers",
	"regione": "Veneto",
	"URL": "https://www.altromercato.it/alternativa-ambiente/",
	"codice": "9815819"
}, {
	"paese": "India",
	"latitudine": "22.95298980078031",
	"longitudine": "78.30062774140022",
	"titolo": "AMBOOTIA",
	"impegno": "Tutela dell'ambiente, Diritti dei lavoratori",
	"prodotto": "t\u00e8",
	"regione": "Asia",
	"URL": "https://www.altromercato.it/ambootia/",
	"codice": "9814906"
}, {
	"paese": "Bolivia",
	"latitudine": "-16.723987956482528",
	"longitudine": "-64.5959932537986",
	"titolo": "ANAPQUI",
	"impegno": "Salvaguardia della biodiversit\u00e0, Sviluppo delle comunit\u00e0, Protezione delle culture indigene, Tutela dell'ambiente",
	"prodotto": "quinoa",
	"regione": "America",
	"URL": "https://www.altromercato.it/anapqui-2/",
	"codice": "9810076"
}, {
	"paese": "Palestina",
	"latitudine": "31.80773605532932",
	"longitudine": "34.92915495645021",
	"titolo": "AOWA",
	"impegno": "Empowerment femminile",
	"prodotto": "saponi artigianali",
	"regione": "Asia",
	"URL": "https://www.altromercato.it/aowa/",
	"codice": "9815126"
}, {
	"paese": "Cile",
	"latitudine": "-26.32725345286224",
	"longitudine": "-70.73411139468564",
	"titolo": "APICOOP",
	"impegno": "Salvaguardia della biodiversit\u00e0, Tutela dell'ambiente, Sviluppo delle comunit\u00e0",
	"prodotto": "miele ",
	"regione": "America",
	"URL": "https://www.altromercato.it/apicoop/",
	"codice": "9810059"
}, {
	"paese": "India",
	"latitudine": "22.95298980078031",
	"longitudine": "78.30062774140022",
	"titolo": "ASHA",
	"impegno": "Sviluppo delle comunit\u00e0, Empowerment femminile, Tutela dell'ambiente, Progetti per i giovani",
	"prodotto": "prodotti artigianali",
	"regione": "Asia",
	"URL": "https://www.altromercato.it/asha/",
	"codice": "9810837"
}, {
	"paese": "Ecuador",
	"latitudine": "-1.530928156469206",
	"longitudine": "-78.00366054796643",
	"titolo": "ASOGUABO",
	"impegno": "Sviluppo delle comunit\u00e0, Tutela dell'ambiente",
	"prodotto": "banane",
	"regione": "America",
	"URL": "https://www.altromercato.it/asoguabo/",
	"codice": ""
}, {
	"paese": "India",
	"latitudine": "22.95298980078031",
	"longitudine": "78.30062774140022",
	"titolo": "ASSISI GARMENTS",
	"impegno": "Empowerment femminile, Sviluppo delle comunit\u00e0, Inclusione sociale",
	"prodotto": "prodotti artigianali",
	"regione": "Asia",
	"URL": "https://www.altromercato.it/assisi-garments/",
	"codice": "9814649"
}, {
	"paese": "India",
	"latitudine": "22.95298980078031",
	"longitudine": "78.30062774140022",
	"titolo": "AUROVILLE / AUROSPIRUL",
	"impegno": "Sviluppo delle comunit\u00e0, Tutela dell'ambiente",
	"prodotto": "alga spirulina",
	"regione": "Asia",
	"URL": "https://www.altromercato.it/aurospirul/",
	"codice": ""
}, {
	"paese": "Per\u00f9",
	"latitudine": "-10.364884945975794",
	"longitudine": "-75.62677526763248",
	"titolo": "AVACH",
	"impegno": "Salvaguardia della biodiversit\u00e0, sviluppo delle comunit\u00e0",
	"prodotto": "Banane biologiche",
	"regione": "",
	"URL": "https://www.altromercato.it/avach/",
	"codice": ""
}, {
	"paese": "Italia",
	"latitudine": "45.34820666225114",
	"longitudine": "12.081755656789971",
	"titolo": "AVEPROBI",
	"impegno": "Tutela dell'ambiente, Salvaguardia della biodiversit\u00e0, Progetti per i giovani",
	"prodotto": "farro",
	"regione": "Veneto",
	"URL": "",
	"codice": "9815400"
}, {
	"paese": "Mozambico",
	"latitudine": "-17.293924246204472",
	"longitudine": "35.912024094456896",
	"titolo": "BAOBAB PRODUCTS MOZAMBIQUE",
	"impegno": "sviluppo delle comunit\u00e0, empowerment femminile",
	"prodotto": "baobab",
	"regione": "Africa",
	"URL": "https://www.altromercato.it/baobab-products-mozambique/",
	"codice": "80802004"
}, {
	"paese": "Senegal",
	"latitudine": "14.345921440427693",
	"longitudine": "-14.698158509312147",
	"titolo": "BFCS",
	"impegno": "Sviluppo delle comunit\u00e0",
	"prodotto": "baobab biologico",
	"regione": "Africa",
	"URL": "https://www.altromercato.it/bfcs/",
	"codice": "9815801"
}, {
	"paese": "Sri Lanka",
	"latitudine": "7.873054",
	"longitudine": "80.771797",
	"titolo": "BIO FOODS",
	"impegno": "Tutela dell'ambiente, Sviluppo delle comunit\u00e0",
	"prodotto": "t\u00e8 biologico",
	"regione": "Asia",
	"URL": "https://www.altromercato.it/sofa/",
	"codice": "9813787"
}, {
	"paese": "Per\u00f9",
	"latitudine": "-10.559029543007851",
	"longitudine": "-74.97537679221331",
	"titolo": "CAGMA",
	"impegno": "Protezione delle culture indigene, Salvaguardia della biodiversit\u00e0",
	"prodotto": "quinoa",
	"regione": "America",
	"URL": "https://www.altromercato.it/cagma/",
	"codice": "1111116"
}, {
	"paese": "Per\u00f9",
	"latitudine": "-10.559029543007851",
	"longitudine": "-74.97537679221331",
	"titolo": "CANDELA",
	"impegno": "Tutela dell'ambiente, Conservazione della foresta, Salvaguardia della biodiversit\u00e0",
	"prodotto": "noci dell'Amazzonia, olio di sacha inchi",
	"regione": "",
	"URL": "https://www.altromercato.it/candela/",
	"codice": "9811164"
}, {
	"paese": "Per\u00f9",
	"latitudine": "-10.559029543007851",
	"longitudine": "-74.97537679221331",
	"titolo": "CAS",
	"impegno": "sviluppo delle comunit\u00e0, empowerment femminile",
	"prodotto": "maca",
	"regione": "America Latina",
	"URL": "https://www.altromercato.it/valle-del-cunas-cas/",
	"codice": "9814296"
}, {
	"paese": "Italia",
	"latitudine": "45.483053436404624",
	"longitudine": "9.186325136236862",
	"titolo": "CASCINA MOROSINA",
	"impegno": "Tutela dell'ambiente",
	"prodotto": "birra ",
	"regione": "Lombardia",
	"URL": "https://www.altromercato.it/cascina-morosina-az-agricola/",
	"codice": "9815607"
}, {
	"paese": "Filippine",
	"latitudine": "12.879721",
	"longitudine": "121.774017",
	"titolo": "CCAP",
	"impegno": "Sviluppo delle comunit\u00e0",
	"prodotto": "prodotti artigianali",
	"regione": "Asia",
	"URL": "https://www.altromercato.it/ccap/",
	"codice": "9810092"
}, {
	"paese": "Guatemala",
	"latitudine": "15.637340623621812",
	"longitudine": "-90.0737255407846",
	"titolo": "CHAJUL",
	"impegno": "Protezione delle culture indigene, Sviluppo delle comunit\u00e0",
	"prodotto": "caff\u00e8 ",
	"regione": "America",
	"URL": "https://www.altromercato.it/chajul/",
	"codice": "9810047"
}, {
	"paese": "Ecuador",
	"latitudine": "-1.530928156469206",
	"longitudine": "-78.00366054796643",
	"titolo": "CHANKUAP",
	"impegno": "Protezione delle culture indigene",
	"prodotto": "oli essenziali",
	"regione": "America",
	"URL": "https://www.altromercato.it/chankuap/",
	"codice": "9811232"
}, {
	"paese": "Nepal",
	"latitudine": "28.271671047023244",
	"longitudine": "83.78957358704992",
	"titolo": "CHILDREN NEPAL",
	"impegno": "Progetti per i giovani, Empowerment femminile",
	"prodotto": "prodotti artigianali",
	"regione": "Asia",
	"URL": "https://www.altromercato.it/children-nepal/",
	"codice": "9814342"
}, {
	"paese": "Per\u00f9",
	"latitudine": "-10.559029543007851",
	"longitudine": "-74.97537679221331",
	"titolo": "CIAP",
	"impegno": "Promozione artigianato locale, Sviluppo delle comunit\u00e0",
	"prodotto": "prodotti artigianali",
	"regione": "America",
	"URL": "https://www.altromercato.it/chi-siamo/ciap/",
	"codice": "9811735"
}, {
	"paese": "Italia",
	"latitudine": "45.59959293835452",
	"longitudine": "10.114297538157764",
	"titolo": "CLARABELLA",
	"impegno": "Tutela dell'ambiente, Inclusione sociale",
	"prodotto": "vino",
	"regione": "Lombardia",
	"URL": "https://www.altromercato.it/clarabella/",
	"codice": "9815906"
}, {
	"paese": "Colombia",
	"latitudine": "2.213444",
	"longitudine": "-75.639107",
	"titolo": "COLCOCOA",
	"impegno": "lavoro comunitario, sviluppo delle comunit\u00e0 rurali, sostenibilit\u00e0 ambientale.",
	"prodotto": "Cacao",
	"regione": "America",
	"URL": "https://www.altromercato.it/colcocoa",
	"codice": "1111125"
}, {
	"paese": "Repubblica Dominicana",
	"latitudine": "18.91099364771761",
	"longitudine": "-70.08696601869427",
	"titolo": "CONACADO",
	"impegno": "Sviluppo delle comunit\u00e0, Empowerment femminile, Progetti per i giovani",
	"prodotto": "cacao",
	"regione": "America",
	"URL": "https://www.altromercato.it/conacado/",
	"codice": "9814799"
}, {
	"paese": "Italia",
	"latitudine": "44.458110373432",
	"longitudine": "12.283228068409366",
	"titolo": "CONSORZIO BIA",
	"impegno": "Tutela dell'ambiente, Inclusione sociale",
	"prodotto": "ortofrutta",
	"regione": "",
	"URL": "https://www.altromercato.it/consorzio-bia/",
	"codice": ""
}, {
	"paese": "Italia",
	"latitudine": "41.6581971683766",
	"longitudine": "15.589242965049397",
	"titolo": "COOP. CAMPO DEI MIRACOLI",
	"impegno": "Economia carceraria, Inclusione sociale",
	"prodotto": "taralli",
	"regione": "Puglia",
	"URL": "https://www.altromercato.it/campo-dei-miracoli/",
	"codice": "9814236"
}, {
	"paese": "Nicaragua",
	"latitudine": "12.874251030833063",
	"longitudine": "-85.10009745877824",
	"titolo": "COOP. DEL CAMPO",
	"impegno": "Tutela dell'ambiente, Sviluppo delle comunit\u00e0, Empowerment femminile",
	"prodotto": "arachidi, sesamo, olio di sesamo",
	"regione": "America",
	"URL": "https://www.altromercato.it/del-campo/",
	"codice": "9813344"
}, {
	"paese": "Bosnia ed Erzegovina",
	"latitudine": "44.6160434565474",
	"longitudine": "17.76574525532009",
	"titolo": "COOP. INSIEME",
	"impegno": "Empowerment femminile, Recupero aree colpite da conflitti",
	"prodotto": "confetture",
	"regione": "Europa",
	"URL": "https://www.altromercato.it/opz-insieme-bosnia/",
	"codice": "9814976"
}, {
	"paese": "Per\u00f9",
	"latitudine": "-12.101622",
	"longitudine": "-76.985037",
	"titolo": "COOP. TOCACHE",
	"impegno": "tutela dell'ambiente, lotta ai cambiamenti climatici",
	"prodotto": "cacao",
	"regione": "America Latina",
	"URL": "https://www.altromercato.it/tocache/",
	"codice": "9816678"
}, {
	"paese": "Costa Rica",
	"latitudine": "10.390206111979014",
	"longitudine": "-83.77044206939408",
	"titolo": "COOPEAGRI",
	"impegno": "Tutela dell'ambiente, Salvaguardia della biodiversit\u00e0, Diritti dei lavoratori",
	"prodotto": "zucchero",
	"regione": "America",
	"URL": "https://www.altromercato.it/coopeagri/",
	"codice": "9810722"
}, {
	"paese": "Brasile",
	"latitudine": "-8.337107569185324",
	"longitudine": "-53.64578145146514",
	"titolo": "COOPERVITAE",
	"impegno": "Sviluppo delle comunit\u00e0",
	"prodotto": "caff\u00e8 ",
	"regione": "America",
	"URL": "https://www.altromercato.it/cooperavitae/",
	"codice": "9812826"
}, {
	"paese": "Brasile",
	"latitudine": "-8.337107569185324",
	"longitudine": "-53.64578145146514",
	"titolo": "COOPFAM",
	"impegno": "Sviluppo delle comunit\u00e0, Empowerment femminile",
	"prodotto": "caff\u00e8 ",
	"regione": "America",
	"URL": "https://www.altromercato.it/coopfam/",
	"codice": "9813241"
}, {
	"paese": "Argentina",
	"latitudine": "-35.11034258318829",
	"longitudine": "-65.66435018337475",
	"titolo": "COOPSOL",
	"impegno": "Sviluppo delle comunit\u00e0, Tutela dell'ambiente, Salvaguardia della biodiversit\u00e0",
	"prodotto": "miele, curcuma",
	"regione": "America",
	"URL": "https://www.altromercato.it/coopsol/",
	"codice": "9812692"
}, {
	"paese": "Guatemala",
	"latitudine": "14.807360777245137",
	"longitudine": "-92.4479720689467",
	"titolo": "COPAVIC",
	"impegno": "sviluppo delle comunit\u00e0",
	"prodotto": "prodotti artigianali",
	"regione": "America Latina",
	"URL": "https://www.altromercato.it/copavic/",
	"codice": "9810053"
}, {
	"paese": "Brasile",
	"latitudine": "-8.337107569185324",
	"longitudine": "-53.64578145146514",
	"titolo": "COPERMATE",
	"impegno": "Sviluppo delle comunit\u00e0, Diritti dei lavoratori",
	"prodotto": "mate",
	"regione": "America Latina",
	"URL": "https://www.altromercato.it/copermate/",
	"codice": "1111112"
}, {
	"paese": "Ecuador",
	"latitudine": "-1.530928156469206",
	"longitudine": "-78.00366054796643",
	"titolo": "COPROPAP",
	"impegno": "Sviluppo delle comunit\u00e0, Diritti dei lavoratori",
	"prodotto": "zucchero",
	"regione": "America",
	"URL": "https://www.altromercato.it/copropap/",
	"codice": "9815085"
}, {
	"paese": "Bangladesh",
	"latitudine": "24.125742728296128",
	"longitudine": "90.37325682586248",
	"titolo": "CORR-THE JUTE WORKS",
	"impegno": "Emancipazione femminile, Sviluppo delle comunit\u00e0",
	"prodotto": "prodotti artigianali",
	"regione": "Asia",
	"URL": "https://www.altromercato.it/corr/",
	"codice": "9810096"
}, {
	"paese": "Nicaragua",
	"latitudine": "12.923608809020282",
	"longitudine": "-85.08677612623997",
	"titolo": "COSECHA PARTNERS",
	"impegno": "sviluppo delle comunit\u00e0, tutela dell'ambiente, empowerment femminile",
	"prodotto": "cacao",
	"regione": "America Latina",
	"URL": "https://www.altromercato.it/cosecha-partners/",
	"codice": "9815754"
}, {
	"paese": "Brasile",
	"latitudine": "-8.337107569185324",
	"longitudine": "-53.64578145146514",
	"titolo": "CPSM",
	"impegno": "Protezione delle culture indigene, Tutela dell'ambiente, Salvaguardia della biodiversit\u00e0",
	"prodotto": "guaran\u00e0 nativo",
	"regione": "America",
	"URL": "https://www.altromercato.it/cpsm/",
	"codice": "9814212"
}, {
	"paese": "Vietnam",
	"latitudine": "14.244770744098108",
	"longitudine": "108.37114513923146",
	"titolo": "CRAFT LINK",
	"impegno": "Protezione delle culture indigene",
	"prodotto": "prodotti artigianali",
	"regione": "Asia",
	"URL": "https://www.altromercato.it/craft-link/",
	"codice": "9811353"
}, {
	"paese": "India",
	"latitudine": "22.95298980078031",
	"longitudine": "78.30062774140022",
	"titolo": "CRC",
	"impegno": "Sviluppo delle comunit\u00e0",
	"prodotto": "prodotti artigianali",
	"regione": "Asia",
	"URL": "https://www.altromercato.it/crc/",
	"codice": "9810094"
}, {
	"paese": "India",
	"latitudine": "22.95298980078031",
	"longitudine": "78.30062774140022",
	"titolo": "CREATIVE HANDICRAFTS",
	"impegno": "Empowerment femminile",
	"prodotto": "prodotti artigianali",
	"regione": "Asia",
	"URL": "https://www.altromercato.it/creative-handicraft/",
	"codice": "9810095"
}, {
	"paese": "Bolivia",
	"latitudine": "-16.723987956482528",
	"longitudine": "-64.5959932537986",
	"titolo": "CRISIL",
	"impegno": "Tutela dell'ambiente",
	"prodotto": "prodotti artigianali",
	"regione": "",
	"URL": "",
	"codice": ""
}, {
	"paese": "Per\u00f9",
	"latitudine": "-12.0039457",
	"longitudine": "-77.1178912",
	"titolo": "CURIMANA",
	"impegno": "lavoro comunitario, rispetto dell'ambiente, responsabilit\u00e0 sociale",
	"prodotto": "Cacao",
	"regione": "America",
	"URL": "https://www.altromercato.it/cooperativa-agraria-banaqui-curimana",
	"codice": "1111122"
}, {
	"paese": "Bangladesh",
	"latitudine": "24.125742728296128",
	"longitudine": "90.37325682586248",
	"titolo": "DHAKA HANDICRAFTS",
	"impegno": "promozione artigianato locale, sostegno alle persone povere, tutela ambientale",
	"prodotto": "prodotti artigianali",
	"regione": "Asia",
	"URL": "https://www.altromercato.it/dhaka-handicraft/",
	"codice": "9811309"
}, {
	"paese": "Per\u00f9",
	"latitudine": "-10.559029543007851",
	"longitudine": "-74.97537679221331",
	"titolo": "ECCO EXE",
	"impegno": "Empowerment femminile",
	"prodotto": "prodotti artigianali",
	"regione": "",
	"URL": "",
	"codice": "9810056"
}, {
	"paese": "Bolivia",
	"latitudine": "-16.723987956482528",
	"longitudine": "-64.5959932537986",
	"titolo": "EL CEIBO",
	"impegno": "Tutela dell'ambiente, Salvaguardia della biodiversit\u00e0",
	"prodotto": "cacao",
	"regione": "America",
	"URL": "https://www.altromercato.it/el-ceibo/",
	"codice": "9812718"
}, {
	"paese": "Italia",
	"latitudine": "45.12791697760589",
	"longitudine": "11.712593289413945",
	"titolo": "EL TAMISO",
	"impegno": "Tutela dell'ambiente, Inclusione sociale",
	"prodotto": "ortofrutta",
	"regione": "Veneto",
	"URL": "https://www.altromercato.it/el-tamiso/",
	"codice": ""
}, {
	"paese": "India",
	"latitudine": "22.95298980078031",
	"longitudine": "78.30062774140022",
	"titolo": "ELEMENTS",
	"impegno": "Sviluppo delle comunit\u00e0, Salvaguardia della biodiversit\u00e0",
	"prodotto": "anacardi, olio di cocco",
	"regione": "Asia",
	"URL": "https://www.altromercato.it/elements/",
	"codice": "9812693"
}, {
	"paese": "Per\u00f9",
	"latitudine": "-10.516183625109033",
	"longitudine": "-75.22979164377436",
	"titolo": "ELISUR ORGANIC",
	"impegno": "tutela dell'ambiente, sviluppo delle comunit\u00e0",
	"prodotto": "zenzero, curcuma",
	"regione": "America Latina",
	"URL": "https://www.altromercato.it/elisur-organic/",
	"codice": ""
}, {
	"paese": "India",
	"latitudine": "22.95298980078031",
	"longitudine": "78.30062774140022",
	"titolo": "FAIR FARMING FOUNDATION",
	"impegno": "Salvaguardia della biodiversit\u00e0",
	"prodotto": "riso",
	"regione": "Asia",
	"URL": "https://www.altromercato.it/fair-farming-foundation/",
	"codice": "1111118"
}, {
	"paese": "Egitto",
	"latitudine": "26.677955689936482",
	"longitudine": "30.203533082014474",
	"titolo": "FAIR TRADE EGYPT",
	"impegno": "Sviluppo delle comunit\u00e0, Empowerment femminile, Progetti per i giovani",
	"prodotto": "prodotti artigianali",
	"regione": "Africa",
	"URL": "https://www.altromercato.it/fair-trade-egypt/",
	"codice": "9814621"
}, {
	"paese": "Libano",
	"latitudine": "34.100779053544194",
	"longitudine": "35.86316289603371",
	"titolo": "FAIR TRADE LEBANON",
	"impegno": "Empowerment femminile, Sviluppo delle comunit\u00e0, Recupero aree colpite da conflitti",
	"prodotto": "petali di rosa essiccati",
	"regione": "Asia",
	"URL": "https://www.altromercato.it/fair-trade-lebanon/",
	"codice": "9816412"
}, {
	"paese": "Repubblica Dominicana",
	"latitudine": "18.91099364771761",
	"longitudine": "-70.08696601869427",
	"titolo": "FEDECARES",
	"impegno": "Empowerment femminile, Sviluppo delle comunit\u00e0",
	"prodotto": "caff\u00e8 ",
	"regione": "America",
	"URL": "https://www.altromercato.it/fedecares/",
	"codice": "9814171"
}, {
	"paese": "Guatemala",
	"latitudine": "15.637340623621812",
	"longitudine": "-90.0737255407846",
	"titolo": "FEDECOCAGUA",
	"impegno": "Empowerment femminile, Sviluppo delle comunit\u00e0",
	"prodotto": "caff\u00e8 ",
	"regione": "America",
	"URL": "https://www.altromercato.it/fedecocagua/",
	"codice": "9810914"
}, {
	"paese": "Ecuador",
	"latitudine": "-1.530928156469206",
	"longitudine": "-78.00366054796643",
	"titolo": "FEPP-CAMARI",
	"impegno": "Protezione delle culture indigene, Tutela dell'ambiente, Lotta al cambiamento climatico",
	"prodotto": "riso, cereali, legumi, prodotti artigianali",
	"regione": "America",
	"URL": "https://www.altromercato.it/fepp-camari/",
	"codice": "9810050"
}, {
	"paese": "Colombia",
	"latitudine": "3.7550788864785507",
	"longitudine": "-73.1558518826748",
	"titolo": "FRUANDES",
	"impegno": "Protezione delle culture indigene, Sviluppo delle comunit\u00e0",
	"prodotto": "zucchero, frutta disidratata",
	"regione": "America",
	"URL": "https://www.altromercato.it/fruandes/",
	"codice": "9815043"
}, {
	"paese": "Togo",
	"latitudine": "8.658215715323005",
	"longitudine": "1.103220963267283",
	"titolo": "GEBANA TOGO",
	"impegno": "Sviluppo delle comunit\u00e0",
	"prodotto": "cacao",
	"regione": "Africa",
	"URL": "https://www.altromercato.it/gebana/",
	"codice": "9816658"
}, {
	"paese": "Marocco",
	"latitudine": "31.908575724485356",
	"longitudine": "-6.8776577239499055",
	"titolo": "GIE TARGANINE",
	"impegno": "Empowerment femminile",
	"prodotto": "olio di argan",
	"regione": "Africa",
	"URL": "https://www.altromercato.it/targanine/",
	"codice": "9812450"
}, {
	"paese": "Italia",
	"latitudine": "43.618034991616184",
	"longitudine": "13.54823370748754",
	"titolo": "GIROLOMONI",
	"impegno": "Tutela dell'ambiente,Economia locale",
	"prodotto": "pasta",
	"regione": "Marche",
	"URL": "https://www.altromercato.it/girolomoni/",
	"codice": "9815262"
}, {
	"paese": "India",
	"latitudine": "22.95298980078031",
	"longitudine": "78.30062774140022",
	"titolo": "GODAVARI",
	"impegno": "Empowerment femminile",
	"prodotto": "prodotti artigianali",
	"regione": "Asia",
	"URL": "https://www.altromercato.it/godavari/",
	"codice": "9810099"
}, {
	"paese": "Italia",
	"latitudine": "38.33394235177512",
	"longitudine": "16.307426609464045",
	"titolo": "GOEL BIO",
	"impegno": "Lotta al caporalato e/o alla criminalit\u00e0 mafiosa",
	"prodotto": "ortofrutta",
	"regione": "Calabria",
	"URL": "https://www.altromercato.it/goel/",
	"codice": ""
}, {
	"paese": "Thailandia",
	"latitudine": "15.470502699613883",
	"longitudine": "101.01585441111033",
	"titolo": "GREEN NET",
	"impegno": "Salvaguardia della biodiversit\u00e0, Lotta al cambiamento climatico",
	"prodotto": "riso thai, aloe vera, latte di cocco",
	"regione": "Asia",
	"URL": "https://www.altromercato.it/greennet/",
	"codice": "9812151"
}, {
	"paese": "Sudafrica",
	"latitudine": "-31.116525586916968",
	"longitudine": "24.759217502610543",
	"titolo": "HEIVELD",
	"impegno": "Tutela dell'ambiente",
	"prodotto": "t\u00e8",
	"regione": "Asia",
	"URL": "https://www.altromercato.it/heiveld/",
	"codice": "9819050"
}, {
	"paese": "Nepal",
	"latitudine": "28.233827452380716",
	"longitudine": "83.93021060731735",
	"titolo": "HIMALAYAN BIO TRADE",
	"impegno": "Sviluppo delle comunit\u00e0, Salvaguardia della biodiversit\u00e0, Conservazione della foresta",
	"prodotto": "oli essenziali",
	"regione": "Asia",
	"URL": "https://www.altromercato.it/himalayan-biotrade/",
	"codice": "9812150"
}, {
	"paese": "Palestina",
	"latitudine": "31.5084188357869",
	"longitudine": "34.47447373722998",
	"titolo": "HOLY LAND",
	"impegno": "Sviluppo delle comunit\u00e0, Empowerment femminile",
	"prodotto": "prodotti artigianali",
	"regione": "Asia",
	"URL": "https://www.altromercato.it/holy-land/",
	"codice": "9810868"
}, {
	"paese": "Italia",
	"latitudine": "45.29901024129284",
	"longitudine": "9.498046368835398",
	"titolo": "I GERMOGLI",
	"impegno": "Inclusione sociale",
	"prodotto": "vino, miele",
	"regione": "Lombardia",
	"URL": "https://www.altromercato.it/i-germogli/",
	"codice": "9814795"
}, {
	"paese": "Turchia",
	"latitudine": "38.98078006582555",
	"longitudine": "36.203958000090495",
	"titolo": "ISIK TARIM",
	"impegno": "sviluppo delle comunit\u00e0",
	"prodotto": "uvetta biologica",
	"regione": "Asia",
	"URL": "https://www.altromercato.it/isik-tarim/",
	"codice": "1111120"
}, {
	"paese": "Cambogia",
	"latitudine": "12.608528407892079",
	"longitudine": "104.58238288091613",
	"titolo": "KAMPATRACO",
	"impegno": "Salvaguardia della biodiversit\u00e0, Protezione delle culture indigene, Conservazione della tradizione",
	"prodotto": "Zucchero di cocco",
	"regione": "Asia",
	"URL": "https://www.altromercato.it/kampatraco/",
	"codice": "1111113"
}, {
	"paese": "Tanzania",
	"latitudine": "-6.506121825213189",
	"longitudine": "35.06424835886582",
	"titolo": "KCU",
	"impegno": "Salvaguardia della biodiversit\u00e0, Sviluppo aree rurali",
	"prodotto": "caff\u00e8 ",
	"regione": "Africa",
	"URL": "https://www.altromercato.it/kcu/",
	"codice": "1111111"
}, {
	"paese": "Nepal",
	"latitudine": "28.271671047023244",
	"longitudine": "83.78957358704992",
	"titolo": "KTE",
	"impegno": "tutela dell'ambiente, salvaguardia della biodiversit\u00e0, lotta al cambiamento climatico, emancipazione femminile, sviluppo aree rurali, protezione delle culture indigene",
	"prodotto": "t\u00e8 biologico",
	"regione": "Asia",
	"URL": "https://www.altromercato.it/kte/",
	"codice": ""
}, {
	"paese": "Italia",
	"latitudine": "38.229702140069215",
	"longitudine": "15.577776026274034",
	"titolo": "L'ARCOLAIO",
	"impegno": "Economia carceraria",
	"prodotto": "mandorle, erbe aromatiche ",
	"regione": "Sicilia",
	"URL": "https://www.altromercato.it/larcolaio/",
	"codice": "9812901"
}, {
	"paese": "El Salvador",
	"latitudine": "13.727549280619286",
	"longitudine": "-88.47323716535415",
	"titolo": "LA SEMILLA DE DIOS",
	"impegno": "sviluppo delle comunit\u00e0, inclusione sociale",
	"prodotto": "prodotti artigianali",
	"regione": "America",
	"URL": "https://www.altromercato.it/la-semilla-de-dios/",
	"codice": "9810069"
}, {
	"paese": "Indonesia",
	"latitudine": "-4.126178386310387",
	"longitudine": "122.16508162010658",
	"titolo": "LOMBOK POTTERY CENTRE",
	"impegno": "Empowerment femminile, Sviluppo delle comunit\u00e0",
	"prodotto": "prodotti artigianali",
	"regione": "Asia",
	"URL": "https://www.altromercato.it/lombok/",
	"codice": "9810765"
}, {
	"paese": "Vietnam",
	"latitudine": "14.432239781951013",
	"longitudine": "108.24889137196942",
	"titolo": "MAI VIETNAMESE HANDICRAFTS",
	"impegno": "Empowerment femminile, Sviluppo delle comunit\u00e0, Inclusione sociale",
	"prodotto": "prodotti artigianali",
	"regione": "Asia",
	"URL": "https://www.altromercato.it/mai-handicrafts/",
	"codice": "9811013"
}, {
	"paese": "Messico",
	"latitudine": "23.634501",
	"longitudine": "-102.552788",
	"titolo": "MAJOMUT",
	"impegno": "Sviluppo delle comunit\u00e0, Tutela dell'ambiente, Lotta al cambiamento climatico, Protezione delle culture indigene",
	"prodotto": "caff\u00e8 ",
	"regione": "America",
	"URL": "https://www.altromercato.it/majomut/",
	"codice": "9812577"
}, {
	"paese": "Paraguay",
	"latitudine": "-23.19662664071423",
	"longitudine": "-58.48410546629208",
	"titolo": "MANDUVIR\u00c0",
	"impegno": "Sviluppo delle comunit\u00e0",
	"prodotto": "zucchero",
	"regione": "America",
	"URL": "https://www.altromercato.it/manduvira/",
	"codice": "9812607"
}, {
	"paese": "Per\u00f9",
	"latitudine": "-10.406249813079535",
	"longitudine": "-75.34042320992646",
	"titolo": "MANOS AMIGAS",
	"impegno": "Sviluppo delle comunit\u00e0",
	"prodotto": "prodotti artigianali",
	"regione": "Asia",
	"URL": "https://www.altromercato.it/chi-siamo/manos-amigas/",
	"codice": "9815264"
}, {
	"paese": "Ecuador",
	"latitudine": "-1.3112895963433697",
	"longitudine": "-78.83437395654884",
	"titolo": "MAQUITA (MCCH)",
	"impegno": "Sviluppo delle comunit\u00e0",
	"prodotto": "cacao, confettura di frutta",
	"regione": "America",
	"URL": "https://www.altromercato.it/mcch/",
	"codice": "9810070"
}, {
	"paese": "Kenya",
	"latitudine": "0.38350853379651767",
	"longitudine": "37.854422370110676",
	"titolo": "MERU HERBS",
	"impegno": "Empowerment femminile, Sviluppo delle comunit\u00e0, Tutela dell'ambiente",
	"prodotto": "ibisco",
	"regione": "Africa",
	"URL": "https://www.altromercato.it/meru-herbs/",
	"codice": "9810027"
}, {
	"paese": "Messico",
	"latitudine": "23.634501",
	"longitudine": "-102.552788",
	"titolo": "MIELES DEL SUR",
	"impegno": "Salvaguardia della biodiversit\u00e0",
	"prodotto": "miele",
	"regione": "America",
	"URL": "https://www.altromercato.it/mieles-del-sur/",
	"codice": "9812311"
}, {
	"paese": "Indonesia",
	"latitudine": "-4.476750051205633",
	"longitudine": "121.68168318929048",
	"titolo": "MITRA BALI",
	"impegno": "Sviluppo delle comunit\u00e0",
	"prodotto": "prodotti artigianali",
	"regione": "Asia",
	"URL": "https://www.altromercato.it/mitra-bali/",
	"codice": "9813129"
}, {
	"paese": "Mauritius (Isole)",
	"latitudine": "-20.348404",
	"longitudine": "57.552151",
	"titolo": "MSS",
	"impegno": "Sviluppo delle comunit\u00e0, Tutela dell'ambiente",
	"prodotto": "zucchero",
	"regione": "MSS",
	"URL": "https://www.altromercato.it/mss/",
	"codice": "9814165"
}, {
	"paese": "Bolivia",
	"latitudine": "-16.678541055163183",
	"longitudine": "-65.43013350043445",
	"titolo": "NATURALEZA",
	"impegno": "Sviluppo delle comunit\u00e0, Lotta alla criminalit\u00e0, Salvaguardia della biodiversit\u00e0",
	"prodotto": "erbe, frutta biologica",
	"regione": "America",
	"URL": "https://www.altromercato.it/naturaleza/",
	"codice": "9810077"
}, {
	"paese": "Thailandia",
	"latitudine": "15.491834999954461",
	"longitudine": "100.92616686794993",
	"titolo": "NGC - The Natural Growth Company",
	"impegno": "sviluppo delle comunit\u00e0",
	"prodotto": "zucchero, riso",
	"regione": "Asia",
	"URL": "https://www.altromercato.it/ngc/",
	"codice": "9816551"
}, {
	"paese": "Per\u00f9",
	"latitudine": "-12.057980",
	"longitudine": "-77.037132",
	"titolo": "NORANDINO",
	"impegno": "Sviluppo delle comunit\u00e0, Tutela dell'ambiente, Lotta al cambiamento climatico, Conservazione della foresta",
	"prodotto": "cacao, caff\u00e8",
	"regione": "America",
	"URL": "https://www.altromercato.it/norandino/",
	"codice": "9815556"
}, {
	"paese": "Italia",
	"latitudine": "41.31943741595311",
	"longitudine": "16.284186918268443",
	"titolo": "O.P. AGRITALIA",
	"impegno": "Tutela dell'ambiente, Inclusione sociale",
	"prodotto": "uva biologica",
	"regione": "Puglia",
	"URL": "https://www.altromercato.it/op-agritalia/",
	"codice": ""
}, {
	"paese": "Thailandia",
	"latitudine": "15.406950230980692",
	"longitudine": "101.19086832145118",
	"titolo": "OASIS",
	"impegno": "Salvaguardia della biodiversit\u00e0",
	"prodotto": "riso",
	"regione": "Asia",
	"URL": "https://www.altromercato.it/oasis/",
	"codice": "9815912"
}, {
	"paese": "Palestina",
	"latitudine": "31.49627596003206",
	"longitudine": "34.471247681867055",
	"titolo": "PARC",
	"impegno": "Sviluppo delle comunit\u00e0, Difesa del territorio, Tutela dell'ambiente, Produzione biologica",
	"prodotto": "cous cous, datteri mejoul, mandorle, olio di oliva",
	"regione": "Asia",
	"URL": "https://www.altromercato.it/parc/",
	"codice": "9810986"
}, {
	"paese": "Indonesia",
	"latitudine": "-4.301462794746793",
	"longitudine": "123.08471081003235",
	"titolo": "PEKERTI",
	"impegno": "Sviluppo delle comunit\u00e0",
	"prodotto": "prodotti artigianali",
	"regione": "Asia",
	"URL": "https://www.altromercato.it/pekerti/",
	"codice": "9810110"
}, {
	"paese": "Italia",
	"latitudine": "45.50418876720588",
	"longitudine": "12.314724557662613",
	"titolo": "PERLAGE",
	"impegno": "Tutela dell'ambiente, Salvaguardia della biodiversit\u00e0, Inclusione sociale",
	"prodotto": "vino prosecco",
	"regione": "Veneto",
	"URL": "https://www.altromercato.it/perlage/",
	"codice": "9815606"
}, {
	"paese": "Filippine",
	"latitudine": "12.879721",
	"longitudine": "121.774017",
	"titolo": "PFTC",
	"impegno": "Tutela della democrazia, Giustizia sociale, Sviluppo delle comunit\u00e0",
	"prodotto": "zucchero",
	"regione": "Asia",
	"URL": "https://www.altromercato.it/pftc/",
	"codice": "9810109"
}, {
	"paese": "Italia",
	"latitudine": "41.30611759844148",
	"longitudine": "15.85026891202361",
	"titolo": "PIETRA DI SCARTO",
	"impegno": "Inclusione sociale, Lotta al caporalato e/o alla criminalit\u00e0 mafiosa, Tutela dell'ambiente",
	"prodotto": "pomodoro, olive belle di Cerignola",
	"regione": "Puglia",
	"URL": "https://www.altromercato.it/pietra-di-scarto/",
	"codice": "9811482"
}, {
	"paese": "Sri Lanka",
	"latitudine": "7.6166928140764005",
	"longitudine": "80.60669430368665",
	"titolo": "PODIE",
	"impegno": "Tutela dell'ambiente, Lotta al cambiamento climatico, Sviluppo delle comunit\u00e0",
	"prodotto": "spezie ",
	"regione": "Asia",
	"URL": "https://www.altromercato.it/podie/",
	"codice": "9810108"
}, {
	"paese": "India",
	"latitudine": "22.99210071046134",
	"longitudine": "79.22032598780437",
	"titolo": "POTONG - PTWWC",
	"impegno": "Sviluppo delle comunit\u00e0",
	"prodotto": "t\u00e8 ",
	"regione": "Asia",
	"URL": "https://www.altromercato.it/potong/",
	"codice": "9815488"
}, {
	"paese": "Filippine",
	"latitudine": "12.879721",
	"longitudine": "121.774017",
	"titolo": "PREDA",
	"impegno": "Tutela dei minori, Progetti per i giovani",
	"prodotto": "mango essiccato",
	"regione": "Asia",
	"URL": "https://www.altromercato.it/preda/",
	"codice": "9815313"
}, {
	"paese": "Italia",
	"latitudine": "41.1362565919459",
	"longitudine": "16.903021820670908",
	"titolo": "PRIMA BIO",
	"impegno": "Tutela dell'ambiente, Lotta al caporalato e/o alla criminalit\u00e0 mafiosa",
	"prodotto": "pomodoro",
	"regione": "Puglia",
	"URL": "https://www.altromercato.it/prima-bio/",
	"codice": "9816232"
}, {
	"paese": "Bangladesh",
	"latitudine": "24.17387304419218",
	"longitudine": "89.011108595934806",
	"titolo": "PROKRITEE",
	"impegno": "Emancipazione femminile, Sviluppo delle comunit\u00e0, Tutela dell'ambiente",
	"prodotto": "prodotti artigianali",
	"regione": "Asia",
	"URL": "https://www.altromercato.it/prokritee/",
	"codice": "9810104"
}, {
	"paese": "India",
	"latitudine": "22.870683527687525",
	"longitudine": "79.08849006161373",
	"titolo": "PUSHPANJALI FAIR TRADE",
	"impegno": "sviluppo delle comunit\u00e0",
	"prodotto": "prodotti artigianali",
	"regione": "Asia",
	"URL": "https://www.altromercato.it/pushpanjali/",
	"codice": "9810113"
}, {
	"paese": "Madagascar",
	"latitudine": "-21.208027844195012",
	"longitudine": "46.17392569171382",
	"titolo": "RAVINALA",
	"impegno": "Salvaguardia della biodiversit\u00e0",
	"prodotto": "oli essenziali",
	"regione": "",
	"URL": "",
	"codice": "9813910"
}, {
	"paese": "Per\u00f9",
	"latitudine": "-12.057980",
	"longitudine": "-77.037132",
	"titolo": "REDESIGN",
	"impegno": "Lotta al cambiamento climatico, Tutela dell'ambiente, Sviluppo aree rurali",
	"prodotto": "baby banana",
	"regione": "America",
	"URL": "https://www.altromercato.it/redesign/",
	"codice": ""
}, {
	"paese": "Italia",
	"latitudine": "37.62813854630621",
	"longitudine": "13.648698656392467",
	"titolo": "RINASCITA",
	"impegno": "Tutela dell'ambiente, Lotta al caporalato e/o alla criminalit\u00e0 mafiosa",
	"prodotto": "pomodoro",
	"regione": "Sicilia",
	"URL": "https://www.altromercato.it/rinascita/",
	"codice": "9815938"
}, {
	"paese": "Nepal",
	"latitudine": "28.31123029223547",
	"longitudine": "84.06187463219679",
	"titolo": "SANA HASTAKALA",
	"impegno": "Sviluppo delle comunit\u00e0, Empowerment femminile",
	"prodotto": "prodotti artigianali",
	"regione": "Asia",
	"URL": "https://www.altromercato.it/sana-hastakala/",
	"codice": "9813038"
}, {
	"paese": "Thailandia",
	"latitudine": "15.258616307979665",
	"longitudine": "100.50971603613279",
	"titolo": "SANG ARUN",
	"impegno": "Sviluppo delle comunit\u00e0, Empowerment femminile",
	"prodotto": "prodotti artigianali",
	"regione": "Asia",
	"URL": "https://www.altromercato.it/sang-arun/",
	"codice": "9814626"
}, {
	"paese": "Guinea Bissau",
	"latitudine": "11.803749",
	"longitudine": "-15.180413",
	"titolo": "SAO FRANCISCO",
	"impegno": "Salvaguardia della biodiversit\u00e0",
	"prodotto": "anacardi",
	"regione": "",
	"URL": "",
	"codice": ""
}, {
	"paese": "India",
	"latitudine": "22.951640413795946",
	"longitudine": "79.70372438383679",
	"titolo": "SASHA",
	"impegno": "Sviluppo delle comunit\u00e0, Empowerment femminile",
	"prodotto": "prodotti artigianali",
	"regione": "Asia",
	"URL": "https://www.altromercato.it/sasha/",
	"codice": "9810122"
}, {
	"paese": "Sri Lanka",
	"latitudine": "7.551351475371211",
	"longitudine": "80.57922848573027",
	"titolo": "SELYN",
	"impegno": "Empowerment femminile",
	"prodotto": "prodotti artigianali",
	"regione": "Asia",
	"URL": "https://www.altromercato.it/selyn/",
	"codice": "9811733"
}, {
	"paese": "Etiopia",
	"latitudine": "8.556852301421037",
	"longitudine": "39.14498394309118",
	"titolo": "SIDAMA",
	"impegno": "Sviluppo delle comunit\u00e0, Tutela dell'ambiente",
	"prodotto": "caff\u00e8 ",
	"regione": "Africa",
	"URL": "https://www.altromercato.it/sidama/",
	"codice": "9812413"
}, {
	"paese": "Thailandia",
	"latitudine": "15.406950230980692",
	"longitudine": "100.94916912343497",
	"titolo": "SOAP-N-SCENT",
	"impegno": "Uguaglianza sociale, Tutela dell'ambiente",
	"prodotto": "saponi artigianali, oli essenziali",
	"regione": "Asia",
	"URL": "https://www.altromercato.it/soap-n-scent/",
	"codice": "9816784"
}, {
	"paese": "Sri Lanka",
	"latitudine": "7.873054",
	"longitudine": "80.771797",
	"titolo": "SOFA",
	"impegno": "Tutela dell'ambiente, Sviluppo delle comunit\u00e0",
	"prodotto": "t\u00e8 biologico, cocco",
	"regione": "Asia",
	"URL": "https://www.altromercato.it/sofa/",
	"codice": "9814423"
}, {
	"paese": "Nicaragua",
	"latitudine": "12.93854904356773",
	"longitudine": "-85.46811181058851",
	"titolo": "SOPPEXCCA",
	"impegno": "Sviluppo delle comunit\u00e0, Empowerment femminile, Progetti per i giovani",
	"prodotto": "caff\u00e8 ",
	"regione": "America",
	"URL": "https://www.altromercato.it/soppexcca/",
	"codice": "9812591"
}, {
	"paese": "Filippine",
	"latitudine": "12.879721",
	"longitudine": "121.774017",
	"titolo": "SPFTC",
	"impegno": "Sviluppo delle comunit\u00e0",
	"prodotto": "mango essiccato, olio di cocco",
	"regione": "Asia",
	"URL": "https://www.altromercato.it/spftc/",
	"codice": "9812335"
}, {
	"paese": "India",
	"latitudine": "23.113408816379053",
	"longitudine": "80.09923216240877",
	"titolo": "ST. MARY MAHILA",
	"impegno": "Tutela dell'ambiente, Salvaguardia della biodiversit\u00e0, Empowerment femminile",
	"prodotto": "prodotti artigianali",
	"regione": "Asia",
	"URL": "https://www.altromercato.it/st-mary-mahila/",
	"codice": "9810123"
}, {
	"paese": "Marocco",
	"latitudine": "31.90828592556827",
	"longitudine": "-6.918180841385691",
	"titolo": "TALIOUINE",
	"impegno": "Sviluppo delle comunit\u00e0, Protezione delle culture indigene",
	"prodotto": "zafferano",
	"regione": "Africa",
	"URL": "https://www.altromercato.it/taliouine/",
	"codice": "9813260"
}, {
	"paese": "Sierra Leone",
	"latitudine": "8.572809792604444",
	"longitudine": "-11.684360112334124",
	"titolo": "TRADIN ORGANIC",
	"impegno": "Sviluppo delle comunit\u00e0, Tutela dell'ambiente",
	"prodotto": "cacao",
	"regione": "Africa",
	"URL": "https://www.altromercato.it/tradin-organic/",
	"codice": "9816500"
}, {
	"paese": "Messico",
	"latitudine": "23.634501",
	"longitudine": "-102.552788",
	"titolo": "TZELTAL TZOTZIL",
	"impegno": "Protezione delle culture indigene, Tutela dell'ambiente",
	"prodotto": "caff\u00e8 ",
	"regione": "America Latina",
	"URL": "https://www.altromercato.it/tzeltal-tzotzil/",
	"codice": "9815300"
}, {
	"paese": "Messico",
	"latitudine": "24.137591446373296",
	"longitudine": "-101.53421030187586",
	"titolo": "UCIRI",
	"impegno": "Tutela dell'ambiente, Protezione delle culture indigene, Sviluppo delle comunit\u00e0",
	"prodotto": "caff\u00e8 ",
	"regione": "",
	"URL": "https://www.altromercato.it/sostenibilita-agita/people/uciri/",
	"codice": "9810054"
}, {
	"paese": "Kenya",
	"latitudine": "0.4397227010934399",
	"longitudine": "37.498316069340646",
	"titolo": "UNDUGU",
	"impegno": "Sviluppo delle comunit\u00e0",
	"prodotto": "prodotti artigianali",
	"regione": "Africa",
	"URL": "https://www.altromercato.it/undugu/",
	"codice": "9810038"
}, {
	"paese": "Ecuador",
	"latitudine": "-1.3112881280294235",
	"longitudine": "-78.3737363742176",
	"titolo": "UNOCACE",
	"impegno": "tutela dell'ambiente, salvaguardia della biodiversit\u00e0, sviluppo delle comunit\u00e0",
	"prodotto": "cacao",
	"regione": "America Latina",
	"URL": "https://www.altromercato.it/unocace/",
	"codice": "1111119 "
}, {
	"paese": "Bosnia ed Erzegovina",
	"latitudine": "43.858181",
	"longitudine": "18.412340",
	"titolo": "UPPM",
	"impegno": "Recupero aree colpite da conflitti",
	"prodotto": "tisane",
	"regione": "Europa",
	"URL": "https://www.altromercato.it/uppm-bosnia/",
	"codice": "9813197"
}, {
	"paese": "Italia",
	"latitudine": "37.63765309474069",
	"longitudine": "13.932070968795207",
	"titolo": "VALDIBELLA",
	"impegno": "Inclusione sociale, Salvaguardia della biodiversit\u00e0",
	"prodotto": "vino, ceci per farina, lenticchie, avocado, limoni",
	"regione": "Sicilia",
	"URL": "https://www.altromercato.it/valdibella/",
	"codice": "9815609"
}, {
	"paese": "India",
	"latitudine": "22.951640413795946",
	"longitudine": "79.70372438383679",
	"titolo": "WSSS",
	"impegno": "Sviluppo delle comunit\u00e0, Protezione della migrazione verso le citt\u00e0",
	"prodotto": "caff\u00e8 ",
	"regione": "Asia",
	"URL": "https://www.altromercato.it/wsss-wayanad/",
	"codice": "9815433"
}, {
	"paese": "Thailandia",
	"latitudine": "15.27981330823659",
	"longitudine": "100.77338788851411",
	"titolo": "Y DEVELOPMENT",
	"impegno": "Sviluppo delle comunit\u00e0",
	"prodotto": "prodotti artigianali",
	"regione": "Asia",
	"URL": "https://www.altromercato.it/y-development/",
	"codice": "9811073"
}, {
	"paese": "Nicaragua",
	"latitudine": "13.09195",
	"longitudine": "-86.3582",
	"titolo": "PRODECOOP",
	"impegno": "parit\u00e0 di genere, rispetto dell'ambiente, responsabilit\u00e0 sociale",
	"prodotto": "Caff\u00e8",
	"regione": "America",
	"URL": "https://www.altromercato.it/prodecoop/",
	"codice": "1111123"
}];